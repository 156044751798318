import Link from "next/link";
import React, {useEffect, useState} from "react";
import Image from "next/image";

const Header = ({handleHidden}) => {
    const [scroll, setScroll] = useState(0);
    useEffect(() => {
        let isMounted = true;

        document.addEventListener("scroll", () => {
            const scrollCheck = window.scrollY > 100;
            if (scrollCheck !== scroll && isMounted) {
                setScroll(scrollCheck);
            }
        });

        return () => {
            isMounted = false;
        };
    }, []);
    return <>
        <header
            className={
                scroll
                    ? "bg-transparent sticky-bar mt-4 stick"
                    : "bg-transparent sticky-bar mt-4"
            }
        >
            <div className="container bg-transparent">
                <nav className="bg-transparent flex justify-between items-center py-3">
                    <Link href="/" className="text-3xl font-semibold leading-none">

                        <Image
                            width={125}
                            height={40}
                            src="/assets/imgs/logos/logo.png"
                            alt="Logo Anivia"
                        />

                    </Link>
                    <ul className="hidden lg:flex lg:items-center lg:w-auto lg:space-x-12">
                        <li className="group relative pt-4 pb-4">
                            <Link
                                href="/"
                                className="text-sm font-semibold text-blueGray-600 hover:text-blueGray-500">

                                Home

                            </Link>
                        </li>
                        <li className="pt-4 pb-4">
                            <Link
                                href="/over-ons"
                                className="text-sm font-semibold text-blueGray-600 hover:text-blueGray-500">

                                Over Ons

                            </Link>
                        </li>
                        <li className="pt-4 pb-4">
                            <Link
                                href="/diensten"
                                className="text-sm font-semibold text-blueGray-600 hover:text-blueGray-500">

                                Diensten

                            </Link>
                        </li>
                        <li className="pt-4 pb-4">
                            <Link
                                href="/projecten"
                                className="text-sm font-semibold text-blueGray-600 hover:text-blueGray-500">

                                Projecten

                            </Link>
                        </li>
                        <li className="pt-4 pb-4">
                            <Link
                                href="/contact"
                                className="text-sm font-semibold text-blueGray-600 hover:text-blueGray-500">

                                Contact

                            </Link>
                        </li>
                        {/*<li>*/}
                        {/*    <a href="https://calculator.anivia.be/" target="_blank"*/}
                        {/*       className="cursor-pointer block py-4 px-6 text-xs text-white text-center font-semibold leading-none bg-blue-400 hover:bg-blue-500 rounded">*/}
                        {/*        Bereken uw project*/}
                        {/*    </a>*/}
                        {/*</li>*/}
                    </ul>

                    <div className="lg:hidden flex">
                        <button
                            className="navbar-burger flex items-center py-2 px-3 text-blue-500 hover:text-blue-700 rounded border border-blue-200 hover:border-blue-300"
                            onClick={handleHidden}
                        >
                            <svg
                                className="fill-current h-4 w-4"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <title>Mobile menu</title>
                                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                            </svg>
                        </button>
                    </div>
                </nav>
            </div>
        </header>
    </>;
};

export default Header;
